import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'
import Layout from '../layouts/Layout';
function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`)
}
function loadComponent(component){
  return () => import(`@/components/${component}.vue`);
}
Vue.use(VueRouter)
const routes = [{
  path: '/:language',
  component: Layout,
  beforeEnter: (to, from, next) => { // <------------
    const locale = to.params.language; // 1
    const languages = 'fa,en,fr';
    const supported_locales = languages.split(','); // 2
    if (!supported_locales.includes(locale)) return next('fa'); // 3
    if (i18n.locale !== locale) { // 4
      i18n.locale = locale;
    }
    return next() // 5
  },
  children: [
    {
      path:'',
      name: 'Home',
      component:load('He'),
    },
    {
      path:'tomorrow',
      name:'Tomorrow',
      component:load('Tomorrow'),
      children:[
        {
          path:':tocategory',
          name:'TomorrowCategory',
          component:load('TCategory'),
          meta:{
            showProgressBar:true
          },
          props:{
            menu:true,
          }
        }
      ]
    },
    {
      path:':category',
      name:'Categories',
      component:loadComponent('Cat'),
      children:[
        {
          path:'',
          name:'Category',
          component:load('Categories'),
          props:{
            menu:true,
          },
        },
        {
          path:'products',
          name:'Products',
          component:load('Products')
        },
        {
          path:'projects',
          name : 'Projects',
          component:load('Projects')
        },
        {
          path:'product/:product',
          name:'Product',
          component:load('Product')
        },
        {
          path: 'about',
          name: 'About',
          component: load('About'),
          meta : {
            showProgressBar:true
          },
          props:{
            menu:true,
          },
        },
        {
          path:'representation',
          name: 'Representation',
          component: load('Representation'),
          meta:{
            showProgressBar:true
          },
          props:{
            menu:true,
          }
        },
        {
          path:'contact',
          name: 'Contact',
          component: load('Contact'),
          meta:{
            showProgressBar:true
          },
          props:{
            menu:true,
          }
        }
      ]
    },

  ]
},
{
  path: '*',
  redirect() {
    return 'fa';
  }
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
