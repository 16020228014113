<template>
    <div class="layouts">
        <loader></loader>
        <ltr-layout v-if="language != 'fa' "></ltr-layout>
        <rtl-layout v-else></rtl-layout>
    </div>
</template>

<script>
import LtrLayout from './LtrLayout.vue';
import RtlLayout from './RtlLayout.vue';
import Loader from '@/components/Loader'
export default {
    name:'Layout',
    components:{
        RtlLayout,
        LtrLayout,
        Loader
    },
    data(){
        return {
            title :this.$t('title'),
            language:this.$route.params.language,
        }
    },
    mounted(){
        if (this.language === 'fa') {
            document.getElementById('app').classList.toggle('rtl');
        }
    },
    created(){
        document.title = this.title;
    }
    
}
</script>

<style>

</style>