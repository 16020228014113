<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: 'LtrLAyouts',
    }
</script>

<style lang="scss">
@import url("../assets/font-ltr.css");
</style>