<template>
    <div class="preload">
      <div class="logo1">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168 119"><defs></defs><title>1</title>
          <path class="cls-1" d="M105.9,43.5H106c20.27-.27,35.72,16,35.72,35.72A35.74,35.74,0,0,1,106,114.94h-.47a93.44,93.44,0,0,0,9.59.91,50.6,50.6,0,0,0,43.38-24.52,38.9,38.9,0,0,0-32.78-59.86,38.57,38.57,0,0,0-29.32,13.1,35.46,35.46,0,0,1,9.45-1.06Z"/>
          <path class="cls-2" d="M106,48.13c-9.11,0-16.59,3.4-23,10.18,0,0-25.91,26.12-27.3,27.36l-.85.71A35.87,35.87,0,0,1,24,93.76c-1-.23-2-.49-2.92-.79.34.88.72,1.74,1.12,2.59A36,36,0,0,0,32.71,108.5,35.8,35.8,0,0,0,79.19,106c.34-.33,24-22.78,35.09-33a33.24,33.24,0,0,1,6.22-4.53,29.82,29.82,0,0,1,12.85-3.9A31,31,0,0,0,106,48.13Z"/>
          <path class="cls-3" d="M66.05,52.24c-6.55-3.69-14.33-5.15-22.34-5.15A51.27,51.27,0,0,0,1.59,69.22c0,.11-.05.21-.06.31.88-.48,1.79-.92,2.72-1.32a35.41,35.41,0,0,1,7.65-2.36c8.56-1.62,16.09.84,23.15,4.75l1,.55c.68.41,7.89,5,16.76,10.66L76,58.61C72.34,56.27,69.06,53.93,66.05,52.24Z"/><path class="cls-3" d="M122.24,87a33.79,33.79,0,0,1-7-3.3L112,81.63,87.86,104.36a38.15,38.15,0,0,0,6.6,3.69h0a29.53,29.53,0,0,0,17.28,1.65,31,31,0,0,0,23.85-21.26A30,30,0,0,1,122.24,87Z"/>
          <path class="cls-4" d="M29.58,20.87s6.7,24.78,34.49,22.74c20.86-1.45,30.06-7.36,45.48-14.44,12.69-5.18,45.82-7.84,56,23.41C161.18,23.06,139,5.22,111.88,4.38c-20.5-.73-32.72,10.48-46.8,15.86C50.88,25.89,38.06,25.26,29.58,20.87Z"/></svg>
      </div>
    </div>
</template>

<script>
    export default {
        name:'Loader',
        methods: {
          logoAnimation(){
            this.$anime.timeline({
              duration:10100,
              delay:function(el, i) { return i * 250 },
              //loop:true,
            }).add({
              targets: '.logo1 .cls-3,.logo1 .cls-2,.logo1 .cls-4,.logo1 .cls-1',
              strokeDashoffset: [this.$anime.setDashoffset, 0],
              easing: 'easeInOutSine',
              duration: 1000,
              delay: function(el, i) { return i * 250 },
              direction: 'alternate',
              //loop: true
            }).add({
              targets:'.logo1 .cls-2',
              easing: 'easeInOutSine',
              duration: 700,
              fill:'#f9b729',
              delay: function(el, i) { return i * 250 },
              direction: 'alternate',
            }).add({
              targets:'.logo1 .cls-3',
              easing: 'easeInOutSine',
              duration: 700,
              fill:'#e44135',
              delay: function(el, i) { return i * 250 },
              direction: 'alternate',
            }).add({
              targets:'.logo1 .cls-4,.logo1 .cls-1',
              easing: 'easeInOutSine',
              duration: 700,
              fill:'#0c7da6',
              delay: function(el, i) { return i * 250 },
              direction: 'alternate',
            });
          },
        },
        mounted(){
          this.logoAnimation();
               setTimeout(()=>{
                this.$anime({
                targets:'.preload',
                height: '0',
                weight:'0',
                duration:2000,
                easing: 'easeInOutQuad',
            });
            },5000);
            setTimeout(()=>{
              this.$anime({
              targets:'.logo1 , #Layer_1',
              height: '0',
              weight:'0',
              opacity:0,
              duration:2500,
              easing: 'easeInOutQuad',
          });
            },5000);

        }
    }
</script>

<style lang="scss">
.preload{
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
    background: #000;
    z-index: 400;
}
.logo1{
  max-width: 20%;
  right: 40%;
  margin-top: 15%;
  position: absolute;

}
.logo1 svg{
  width: 100%;
}
.cls-1,.cls-2,.cls-3,.cls-4,.cls-1{fill:none;stroke-miterlimit:10;}
.cls-2{stroke:#f9b729;fill-rule:evenodd;}
.cls-3{stroke:#e44135;}
.cls-4,.cls-1{stroke:#0c7da6;}
</style>
