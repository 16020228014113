import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import NProgress  from 'vue-nprogress-loading-bar'
import VueAnime from 'vue-animejs';
import JQuery from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueFormWizard from 'vue-form-wizard2';
import { localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import fa from 'vee-validate/dist/locale/fa.json';

// Install English and Persian locales.
localize({
  en,
  fa
});

const options = {
  latencyThreshold: 200, // Number of ms before progressbar starts showing
  router: true, // Show progressbar when navigating routes
  http: true // Show progressbar when doing Axios.http or Vue.http
};
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
Vue.use(NProgress, options,router)
Vue.use(VueAnime)
Vue.use(JQuery)
Vue.use(VueFormWizard)
Vue.component('fa', FontAwesomeIcon)
