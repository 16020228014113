<template>
    <div>
    <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name:'RtlLayout',
    }
</script>

<style lang="scss">
@import url("../assets/font.css");
</style>
